var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('portal',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-breadcrumbs',{staticClass:"pa-0 mb-8",attrs:{"items":_vm.breadcrumbsItems},scopedSlots:_vm._u([{key:"divider",fn:function(){return [_c('v-icon',[_vm._v("mdi-chevron-right")])]},proxy:true}])})],1)],1),_c('v-row',[_c('v-col',{staticClass:"d-flex flex-row"},[_c('span',{staticClass:"d-flex align-center justify-center"},[_c('v-btn',{staticClass:"mr-3 secondary--text pa-0 pr-3",attrs:{"text":""},on:{"click":function($event){return _vm.$router.push({ name: 'AgendaStyles' })}}},[_c('v-icon',{attrs:{"size":"30"}},[_vm._v("mdi-chevron-left")]),_vm._v(" Agenda Styles ")],1),_c('h2',{staticClass:"darkGrey--text"},[_vm._v(" Editing "+_vm._s(this.agendaStyleData.name)+" Agenda Style ")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-3",attrs:{"color":"darkGrey","size":"18"}},'v-icon',attrs,false),on),[_vm._v(" mdi-help-circle-outline ")])]}}])},[_c('span',[_vm._v(" Explanation for Board Members ")])])],1),_c('v-spacer'),_c('v-btn',{staticClass:"white--text",attrs:{"depressed":"","height":"40","color":"orange lighten-2"},on:{"click":function($event){return _vm.openModal('createUpdate', 'create')}}},[_c('v-icon',{attrs:{"size":"18"}},[_vm._v("mdi-plus")]),_vm._v(" Add Member ")],1)],1)],1),_c('v-row',{staticClass:"my-6"},[_c('v-col',[_c('v-divider',{staticClass:"custom-divider"})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6","offset":"6"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"2"}},[_vm._v("first")]),_c('v-col',{attrs:{"cols":"10"}},[_c('v-card',{staticClass:"letter-wrapper"},[_c('v-card',{staticClass:"letter-paper"},[_c('div',[_c('div',{staticClass:"letter-column",staticStyle:{"width":"0.5in"}},[_vm._v("first")]),_c('div',{staticClass:"template-divider"}),_c('div',{staticClass:"letter-column",staticStyle:{"width":"1.5in"}},[_vm._v("second")]),_c('div',{staticClass:"template-divider"}),_c('div',{staticClass:"letter-column",staticStyle:{"width":"5.5in"}},[_vm._v("third")])])])],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-card',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":this.boardData.members,"footer-props":{
            'items-per-page-options': [10, 20, 30]
          },"loading":_vm.loading,"search":_vm.search},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
          var item = ref.item;
return [_c('span',{staticClass:"font-weight-bold d-flex py-4"},[_vm._v(" "+_vm._s(item.alias.user.first_name)+" "+_vm._s(item.alias.user.last_name)+" ")])]}},{key:"item.email",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.alias.meta_value)+" ")]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-row justify-center"},_vm._l((_vm.itemsMenu),function(itemMenu,index){return _c('v-tooltip',{key:index,attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"darkGrey","size":"18"},on:{"click":function($event){return _vm.openModal(itemMenu.actions, itemMenu.optional, item.id, item.alias.user.first_name, item.alias.user.last_name)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"20","color":itemMenu.type? itemMenu.type:'darkGrey'}},[_vm._v(" "+_vm._s(itemMenu.icon)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(itemMenu.text)+" ")])])}),1)]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }